import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "rbp.png" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          } 
        }
      }
    }
  `)
  return <div className="m-0 p-0"><Img fixed={data.file.childImageSharp.fixed} /></div>
}
