import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

import logoImage from "../images/logo.svg"

const Header = ({ home }) => {
  const data = useStaticQuery(graphql`
    query {
      homeBackgroundQuery: file(relativePath: { eq: "heroes/home.jpg" }) {
        childImageSharp {
          fixed(height: 800, quality: 95) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      internalBackgroundQuery: file(
        relativePath: { eq: "heroes/internal.jpg" }
      ) {
        childImageSharp {
          fixed(height: 300, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const internalBackgroundImage =
    data.internalBackgroundQuery.childImageSharp.fixed.src

  const homeBackgroundImage = data.homeBackgroundQuery.childImageSharp.fixed.src

  const styles = {
    homeStyle: {
      backgroundImage: `url(${homeBackgroundImage})`,
    },

    internalStyle: {
      backgroundImage: `url(${internalBackgroundImage})`,
    },
  }

  return (
    <>
      <header
        className={"lg:pt-12 " + (home ? "lg:h-screen bg-cover" : "lg:h-64")}
        style={home ? styles.homeStyle : styles.internalStyle}
      >
        <div className="bg-whitetrans">
          <div className="container mx-auto flex flex-wrap px-6 py-8">
            <div className="w-full lg:w-1/2">
              <Link to="/">
                <img src={logoImage} className="h-20" alt="Burmpro logo" />
              </Link>
            </div>
            <div className="w-full lg:w-1/2">
              <ul className="flex flex-wrap lg:float-right pt-6 justify-center">
                <li className="mx-3 my-3 hidden lg:block">
                  <Link to="/" activeClassName="active">
                    Home
                  </Link>
                </li>
                <li className="mx-3 my-3">
                  <Link to="/about" activeClassName="active">
                    About Us
                  </Link>
                </li>
                <li className="mx-3 my-3">
                  <Link to="/projects" activeClassName="active">
                    Our Projects
                  </Link>
                </li>
                <li className="mx-3 my-3">
                  <Link to="/testimonials" activeClassName="active">
                    Testimonials
                  </Link>
                </li>
                <li className="mx-3 my-3">
                  <Link to="/contact" activeClassName="active">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
