import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Header from "./header"
import RbpLogo from "./rbp"
import { FaEnvelope, FaPhone, FaInstagram } from "react-icons/fa"

const Layout = ({ children, home }) => {
  return (
    <>
      <Header home={home} />

      <main className="container mx-auto px-6 pt-6 pb-24">{children}</main>

      <footer className="bg-grey py-8">
        <div className="container mx-auto flex flex-wrap px-6">
          <div className="w-full lg:w-2/3">
            <ul className="text-gray-400 text-sm">
              <li className="mb-4 text-center lg:text-left">
                Copyright &copy; <Link to="/">Burmpro Builders</Link>
              </li>
              <li className="mb-4 text-center lg:text-left">
                <a href="tel:+61398441665">
                  <FaPhone className="inline-block mr-2" />
                  (03) 9844 1665
                </a>
              </li>
              <li className="mb-4 text-center lg:text-left">
                <a href="mailto:info@burmpro.com.au">
                  <FaEnvelope className="inline-block mr-2" />
                  info@burmpro.com.au
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/3 text-center lg:text-right my-4 lg:m-0">
            <RbpLogo  />

            <a href="https://www.instagram.com/burmpro_/">
              <FaInstagram className="inline-block text-centre lg:text-right text-white text-3xl mt-2" />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
